<template>
  <container>
    <div class="header-web">
      <div class="arrows">
        <div @click="iframeBack()">
          <svg-icon class="arrows__item" name="arrow-left" />
        </div>
        <div @click="iframePrev()">
          <svg-icon class="arrows__item" name="arrow-right" />
        </div>
      </div>
      <!-- <span>{{document.querySelector("#iframe").contentWindow}}</span> -->
      <span class="header-web__title">porsche.com</span>
      <router-link to="/charging" class="header-web__btn-cross">
        <svg-icon name="nav-cross" height="60" width="60" />
      </router-link>
    </div>
  </container>
</template>

<script>
import SvgIcon from "../components/IconBase";
import Container from "../components/Container/Container.vue";
export default {
  mounted() {
    let iframe = document.querySelector("#iframe");
    iframe.classList.add("show");
    let app = document.querySelector('#app')
    app.style.cssText = "height: auto;"
  },
  beforeDestroy() {
    let iframe = document.querySelector("#iframe");
    iframe.classList.remove("show");
    let app = document.querySelector('#app')
    app.style.cssText = ""
  },
  methods: {
    iframeBack() {
      window.frames.myFrame.history.back();
    },
    iframePrev() {
      window.frames.myFrame.history.forward();
    },
  },
  components: {
    SvgIcon,
    Container,
  },
};
</script>
<style>
#iframe {
  height: calc(100% - 127px);
  width: 100%;
  display: none;
  border: none;
}
.show {
  display: block !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}
.header-web {
  display: flex;
  align-items: center;
  background-color: #000000;
  height: 127px;
  position: relative;
}
.header-web__title {
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 100%;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.02em;
}
.header-web__btn-cross {
  display: block;
  margin-left: auto;
  width: 60px;
  height: 60px;
  -webkit-tap-highlight-color: transparent;
  z-index: 2;
}
.arrows {
  display: flex;
  width: 120px;
  height: 60px;
  z-index: 2;
}
.arrows__item {
  cursor: pointer;
  display: block;
  width: 60px;
  height: 60px;
  -webkit-tap-highlight-color: transparent;
}
</style>